<!--
 * @Author: 羡国柱
 * @Date: 2023-07-18 14:58:13
 * @LastEditors: 邵澳 1398037353@qq.com
 * @LastEditTime: 2023-08-17 16:28:44
 * @FilePath: \src\components\scrollWrapper\SEduArchives\Sub\ProgrammeAdd.vue
 * @Description: 智慧教学 > 综评档案 > 方案配置 -- 添加
-->
<template>
	<div class="programme-add-pages">
		<el-form  label-position="left" :label-width="formLabelWidth" :model="dengmiDataAdd" :rules="addRules"
			ref="dengmiDataAdd"  size="small"  v-loading="dengmiDataAdd.loading">
			<el-form-item label="方案名称" prop="title" >
				<el-input v-model="dengmiDataAdd.title" name="title" placeholder="请输入方案名称" clearable maxlength="15" show-word-limit></el-input>
			</el-form-item>
			<el-form-item label="选择年级" prop="grade">
				<el-checkbox-group v-model="dengmiDataAdd.grade" @change="getAddGradeInfo">
					<el-checkbox v-for="item in dengmiDataAdd.gradeOptions" :label="item.id" :key="item.id" >
						{{ item.name }}
					</el-checkbox>
				</el-checkbox-group>
			</el-form-item>
			<el-form-item label="关联考试" prop="examList" class="exam-wrap">
				<el-select v-model="dengmiDataAdd.examList" multiple collapse-tags placeholder="关联的考试" @visible-change="getExasmInfoer"
					@remove-tag="removeExasmInfo" ref="exasmSelectBoes">
					<el-option v-for="item in dengmiDataAdd.examOptions" :key="item.id" class="programme-add-exam-wrap-option" :label="item.examName" :value="item.id"></el-option>
				</el-select>
				<el-collapse-transition>
					<div class="exam-wraper-list" v-if="dengmiDataAdd.examListBoes">
						<div class="exam-wraper-list-box">
							<div v-for="item in dengmiDataAdd.examDataList" :key="item.id"
								class="exam-wraper-list-cell" @mouseenter="isOperateShow(item)" @mouseleave="isOperateHide">
								<div class="exams-title">
									<span :title="item.examName">{{ item.examName }}</span>
									<i class="el-icon-error" @click="deleteExam(item)"></i>
								</div>
								<div class="exams-radio">
									<el-checkbox v-model="item.radio" @change="sval => getExamRadio(sval, item)">有附加分</el-checkbox>
								</div>
							</div>
						</div>
						<div class="exam-wraper-arrows">
							<i class="el-icon-caret-top"></i>
						</div>
					</div>
				</el-collapse-transition>
			</el-form-item>
			<div style="height: 150px;" v-show="dengmiDataAdd.hidenHeight"></div>
			<div>
				<div v-for="(item, index) in dengmiDataAdd.appendPList" :key="item.id">
					<div style="color: 16px; font-size: 16px; font-weight: 600; padding-left: 3px; padding-bottom: 24px;" v-if="item.examName">
						{{ '【' + item.examName  + '】'}}关联行为设置
					</div>
					<el-form-item label="起止日期" :prop="'appendPList.' + index + '.detaTime'"
						:rules="{required: true, message: '请选择起止日期', trigger: 'blur'}">
						<el-date-picker v-model="item.detaTime" clearable type="daterange"
							range-separator="至" value-format="yyyy-MM-dd hh:mm:ss" start-placeholder="起始日期" end-placeholder="结束日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item :prop="'appendPList.' + index + '.tableData'" :rules="{required: true, message: '请选择行为', trigger: ['change', 'blur']}">
                        <el-button type="primary" @click="targetShowClick(item)">选择行为</el-button>
					</el-form-item>
					<el-form-item>
						<el-table
							style="width: 100%" 
							border stripe size="mini"
							:data="item.tableData"
							:header-cell-style="{ background:'#F1F1F1', color:'#2B2F33' }">
							<el-table-column label="序ㅤ号" type="index" align="center" width="65px"></el-table-column>
							<el-table-column align="left" label="行ㅤ为" show-overflow-tooltip>
								<template slot-scope="scope">
									<span>{{ scope.row.ruleName }}</span>
								</template>
							</el-table-column>
							<el-table-column align="center" label="运ㅤ算" width="65px">
								<template slot-scope="scope">
									<span v-if="scope.row.ruleType === '1'" style="color: #20EDDC;">-</span>
									<span v-if="scope.row.ruleType === '2'" style="color: #ED2020;">+</span>
								</template>
							</el-table-column>
							<el-table-column align="center" label="分ㅤ值" width="100px">
								<template slot-scope="scope">
									<span>{{ scope.row.score }}</span>
								</template>
							</el-table-column>
							<div slot="empty">
								<p :style="{'marginTop': '23px'}">暂无数据</p>
							</div>
						</el-table>
					</el-form-item>
				</div>
			</div>
			<el-form-item>
				<div style="text-align: right; margin-top: 50px;">
					<el-button :disabled="disabledBtnState" @click="examAddCancel">取消</el-button>
					<el-button type="primary" :disabled="disabledBtnState" @click="examAddConfirm('dengmiDataAdd')">确定</el-button>
				</div>
			</el-form-item>
		</el-form>
		<dialog-wrapper :dialog-obj="dialogTargetObj" @handleClose="handleTargetClose">
			<div class="target-list">
				<div style="display: flex;">
					<div class="target-list-query">
						<el-input v-model="targetName" clearable placeholder="行为名称"></el-input>
					</div>
					<div class="target-list-number">
						<span>已选行为</span>
						<span>{{ dialogTargetObj.targetSeleteList.length }}</span>
					</div>
				</div>

				<div class="target-list-boes">
					<div class="target-list-boes-row target-list-boes-lt">
						<el-tree ref="tree" show-checkbox node-key="id"
							:data="dialogTargetObj.targetDataList"
							:props="dialogTargetObj.defaultProps"
							:default-expanded-keys="dialogTargetObj.expandedKeys"
							:default-checked-keys="dialogTargetObj.checkedkeys"
							:filter-node-method="filterNode"
							@node-click="handleNodeClick"
							@check-change="handleCheckChange">
						</el-tree>
					</div>
					<div class="target-list-boes-row target-list-boes-rt">
						<div v-for="(item, index) in dialogTargetObj.targetSeleteList" :key="index" class="target-list-boes-rt-row">
							<div class="target-selete-cell">
								<div class="target-selete-cell-delete" @click="targetDelete(item)">
									<i class="el-icon-error"></i>
								</div>
								<div class="target-selete-cell-name" :title="item.ruleName">{{ item.ruleName }}</div>
							</div>
						</div>
					</div>
				</div>
				<div style="text-align: right; margin-top: 20px;">
					<el-button @click="targetSeleCancel">取消</el-button>
					<el-button type="primary" @click="targetSeleConfirm">保存</el-button>
				</div>
			</div>
		</dialog-wrapper>
	</div>
</template>

<script>
	import {
		// 业务组件
		FilterData,
		TableData,
		DialogWrapper,
		Pagination,
		// 功能组件
		Error,
		Loading,
		// 工具函数
		throttle,
	} from "common-local";
	import {
		mapState
	} from 'vuex';
	import {
		hasPermission
	} from "@/libs/utils";
	import ExpandFilter from "../../Sub/ExpandFilter";
	import { formatTreeData, treeFind, treeForeach } from "@/libs/utils";
	
	export default {
		name: 'ProgrammeAdd',
		components: {
			FilterData,
			TableData,
			Pagination,
			DialogWrapper,
			// 功能组件
			Error,
			Loading,
			ExpandFilter
		},
		props: {
			dialogAddObj: {
				type: Object
			}
		},
		data() {
			return {
				formLabelWidth: '100px',
				disabledBtnState: false,
				isOperate: '',
				dengmiDataAdd: {
					title: '',
					grade: [],
					gradeOptions: [],
					examListBox: false,
					examListBoes: false,
					hidenHeight: false,
					loading: true,
					exam: '',
					examList: [],
					examSeleteList: [],
					examDataList: [],
					examOptions: [],
					appendPList: []
				},
				addRules: {
					title: [{
						required: true,
						message: "请输入方案名称",
						trigger: "blur"
					}],
					grade: [{
						required: true,
						message: "请选择年级",
						trigger: "blur"
					}],
					examList: [{
						required: true,
						message: "添加关联的考试",
						trigger: "blur"
					}],
					exam: [{
						required: true,
						message: "添加关联的考试",
						trigger: "blur"
					}]
				},
				// 搜索指标
				targetName: '',
				dialogTargetObj: {
					title: '选择行为',
					dialogVisible: false,
					dataId: '',
					width: '600px',
					defaultProps: {
						value: 'id',
						label: 'ruleName',
						children: 'children'
					},
					targetSeleteList: [],
					targetDataList: [],
					// 默认展开
					expandedKeys: [],
					// 默认选中的数据
					checkedkeys: []
				}
			}
		},
		computed: {
			...mapState({
				permissions: (state) => state.permissions
			})
		},
		created() {
			this.init();
		},
		mounted() {
		},
		watch: {
			'dialogAddObj.keyId'(newVal, oldVal) {
				console.log('id================ ',this.dialogAddObj.keyId)
				this.init();
				this.$refs.dengmiDataAdd.resetFields();
				this.dengmiDataAdd.examListBox = false;
				this.dengmiDataAdd.examListBoes = false;
				this.dengmiDataAdd.hidenHeight = false;
				this.dengmiDataAdd.title = '';
				this.dengmiDataAdd.grade = [];
				this.dengmiDataAdd.exam = '';
				this.dengmiDataAdd.appendPList = [];
				this.dengmiDataAdd.examSeleteList = [];
				this.dengmiDataAdd.examList = [];
				this.dengmiDataAdd.examDataList = [];
			},
			targetName(val) {
				this.$refs.tree.filter(val);
			}
		},
		methods: {
			/**
			 * @Description: 加载
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 14:02:07
			 */
			init() {
				this.handleGradeList();
				// this.handleTargetList();
			},
			/**
			 * @Description:获取年级信息列表
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 14:11:07
			 */
			handleGradeList() {
				this.dengmiDataAdd.loading = true;
				this.dengmiDataAdd.grade = [];
				this._fet('/school/schoolOrgan/listByCondition', {
					schoolId: this.$store.state.schoolId,
					organType: '3'
				}).then((res) => {
					if (res.data.code === '200') {
						let reg = res.data.data.list;
						this.dengmiDataAdd.gradeOptions = res.data.data.list;
						for(let k = 0; k < reg.length; k++) {
							this.dengmiDataAdd.grade.push(reg[k].id);
						}
						this.handleExamList(this.dengmiDataAdd.grade);
					} else if (res.data && res.data.msg) {
						this.$message.error(res.data.msg);
						this.dengmiDataAdd.loading = false;
					}
				});
			},
			/**
			 * @Description:获取未关联考试列表
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 14:11:08
			 */
			async handleExamList(gradeIdList) {
				await this._fet('/school/schoolCerProgramme/getNoJoinExamList', {
					gradeIdList: gradeIdList
				}).then((res) => {
					if (res.data.code === '200') {
						this.dengmiDataAdd.examOptions = res.data.data;
						if(this.dengmiDataAdd.examOptions.length <= 0) {
							this.dengmiDataAdd.examListBox = false;
						}

						this.dengmiDataAdd.loading = false;
					} else if (res.data && res.data.msg) {
						this.$message.error(res.data.msg);
						this.dengmiDataAdd.loading = false;
					}
				});
			},
			/**
			 * @Description:指标树形结构数据处理
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-27 16:57:15
			 */
			getsTreeData(data){
				for(let i = 0; i < data.length ; i++){
					if(data[i].behaviorList && data[i].behaviorList.length > 0){
						this.getsTreeData(data[i].behaviorList);
					}else {
						for(let j = 0; j < data.length ; j++) {
							data[j].disabled = false;
							if(data[j].type === '1' ) {
								data[j].disabled = true;
							}
						}
					}
				}
				return data;
			},
			/**
			 * @Description:学校指标行为列表接口
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 14:11:08
			 */
			handleTargetList() {
				let dataList = this.dengmiDataAdd.appendPList;
				let dataId = this.dialogTargetObj.dataId;
				let targeList = [];
				this.dialogTargetObj.expandedKeys = [];
				this.dialogTargetObj.checkedkeys = [];
				this._fet('/school/schoolMoralRule/listByCondition', {
					schoolId: this.$store.state.schoolId,
					ruleName: ''
				}).then((res) => {
					if (res.data.code === '200') {
						let reg = res.data.data;
						reg = this.getsTreeData(reg);
						reg = reg.sort((a, b) => {
							return a.sort - b.sort;
						});
						let targetDataList = formatTreeData(
							JSON.parse(JSON.stringify(reg)),
							'id',
							'parentId'
						);
						treeForeach(targetDataList, 'children', node => {
							if (node.behaviorList && node.behaviorList.length > 0) {
								node.children = node.behaviorList
							}
						})
						this.dialogTargetObj.targetDataList = targetDataList;
						
						for(let j = 0; j < reg.length; j++) {
							if(reg[j].behaviorList.length > 0) {
								this.dialogTargetObj.expandedKeys.push(reg[j].id)
							}
						}
						for(let k = 0; k < dataList.length; k++) {
							if(dataId === dataList[k].id) {
								for(let j = 0; j < dataList[k].tableData.length; j++) {
									// 默认展开
									// if(dataList[k].tableData[j].parentId) {
									// 	this.dialogTargetObj.expandedKeys.push(dataList[k].tableData[j].parentId)
									// }
									// 默认选中的数据
									this.dialogTargetObj.checkedkeys.push(dataList[k].tableData[j].id);
								}
								this.dialogTargetObj.targetSeleteList = dataList[k].tableData;
							}
						}

					} else if (res.data && res.data.msg) {
						this.$message.error(res.data.msg);
					}
				});
			},
			/**
			 * @Description: 获取年级主键
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 14:22:10
			 */
			async getAddGradeInfo(val) {
				if(val.length > 0) {
					await this.handleExamList(val);
				} else {
					this.dengmiDataAdd.examOptions = [];
				}
                const ids = this.dengmiDataAdd.examOptions.map(i => i.id)
                this.dengmiDataAdd.examList = this.dengmiDataAdd.examList.filter(i => ids.includes(i))
                this.getExasmInfoer()
			},
			/**
			 * @Description: 关联考试选择显示
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 14:22:13
			 */
			examFocus() {
				if(this.dengmiDataAdd.grade <= 0) {
					this.$message({
						type: 'warning',
						message: '请选择年级！'
					});
					return false;
				}
				if(this.dengmiDataAdd.examOptions.length > 0) {
					this.dengmiDataAdd.examListBox = true;
					this.dengmiDataAdd.examListBoes = false;
					this.dengmiDataAdd.hidenHeight = true;
				} else {
					this.$message({
						type: 'warning',
						message: '所选年级暂无关联考试信息！'
					});
				}
			},
			/**
			 * @Description: 关联考试下拉框显示/隐藏处理
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-08-12 08:55:16
			 */
			getExasmInfoer(e) {
				// 创建底部按钮
				let str = '';
				let examWrapBtn = document.createElement('div');
				examWrapBtn.setAttribute('class', 'exam-wrapers-btn');
				str += '<span class="btn-cell confirm-exam-btn-add " @click="examSeleConfirm">确定</span>';
				str += '<span class="btn-cell cancel-exam-btn-add" @click="examSeleCancel">取消</span>';
				examWrapBtn.innerHTML = str;
				
				if(e) { 
					this.dengmiDataAdd.hidenHeight = true;
					// 下拉框出现时的操作处理
					
					// 获取下拉框元素并将按钮插入
					let elScrollAdd = document.getElementsByClassName('el-select-dropdown__wrap');
					console.log('新增 ',elScrollAdd)
					
					let elScrollbarWrap = document.getElementsByClassName('el-select-dropdown__wrap')[elScrollAdd.length - 1];
					console.log('elScrollbarWrap', elScrollbarWrap)
					// 插入前先判断有没有按钮，有就先移除
					if(elScrollbarWrap.lastChild.getAttribute('class') === 'exam-wrapers-btn') {
						let examWraperBtn = elScrollbarWrap.getElementsByClassName('exam-wrapers-btn')[0];
						elScrollbarWrap.removeChild(examWraperBtn);
					}
					// 插入操作按钮
					elScrollbarWrap.appendChild(examWrapBtn)
					// 获取操作按钮元素
					let confirmExamBtn = document.getElementsByClassName('confirm-exam-btn-add')[0];
					let cancelExamBtn = document.getElementsByClassName('cancel-exam-btn-add')[0];
					// 关联考试选择确定
					confirmExamBtn.onclick = () => {
						console.log('关联考试选择确定===事件内')
						this.examSeleConfirm();
					}
					// 关联考试选择取消
					cancelExamBtn.onclick = () => {
						console.log('关联考试选择取消===事件内')
						this.examSeleCancel();
					}
				} else {
					// 下拉框隐藏时
					let examDataListBack = this._.cloneDeep(this.dengmiDataAdd.examDataList)
					// console.log("[getExasmInfoer]examDataListBack:", examDataListBack)
					let examSeleList = [];
					let dataSeleList = this.dengmiDataAdd.examList;
					let dataList = this.dengmiDataAdd.examOptions;
					for(let j = 0; j < dataList.length; j++) {
						for(let k = 0; k < dataSeleList.length; k++) {
							if(dataList[j].id === dataSeleList[k]) {
								let obj = this._.cloneDeep(dataList[j])
								let backItem = examDataListBack.find(e => e.id === obj.id)
								obj.radio = backItem ? backItem.radio : ''
								examSeleList.push(obj)
							}
						}
					}
					this.dengmiDataAdd.examDataList = examSeleList;
					let selectedIds = this.dengmiDataAdd.examDataList.filter(e => e.radio).map(e => e.id)
					console.log("[getExasmInfoer]", this.dengmiDataAdd.examDataList, selectedIds, this.dengmiDataAdd.examDataList)
					this.dengmiDataAdd.appendPList = this.dengmiDataAdd.appendPList.filter(e => selectedIds.includes(e.id))
					this.dengmiDataAdd.hidenHeight = this.dengmiDataAdd.examList.length > 0;
					this.dengmiDataAdd.examListBoes = this.dengmiDataAdd.examList.length > 0;
				}
			},
			/**
			 * @Description: 下拉框多选获取删除项主键
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-08-13 14:37:58
			 */
			removeExasmInfo(val) {
				console.log('删除', val)
				let dataList = this.dengmiDataAdd.examDataList;
				
				for(let j = 0; j < dataList.length; j++) {
					if(val === dataList[j].id) {
						this.dengmiDataAdd.examDataList.splice(j, 1)
					}
				}
				
				if(this.dengmiDataAdd.appendPList.length > 0) {
					for(let j = 0; j < this.dengmiDataAdd.appendPList.length; j++) {
						if(val === this.dengmiDataAdd.appendPList[j].examId) {
							this.dengmiDataAdd.appendPList.splice(j, 1);
						}
					}
				}
				
				if(this.dengmiDataAdd.examDataList.length <= 0) {
					this.dengmiDataAdd.examListBoes = false;
					this.dengmiDataAdd.hidenHeight = false;
					
				}
			},
			/**
			 * @Description: 关联考试选择确定
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-08-12 09:56:59
			 */
			examSeleConfirm() {
				if(this.dengmiDataAdd.examList.length <= 0) {
					this.$message({
						type: 'warning',
						message: '请选择关联考试！'
					});
					return false;
				}
				this.$refs.exasmSelectBoes.blur();
			},
			/**
			 * @Description: 关联考试选择取消
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-08-12 10:06:35
			 */
			examSeleCancel() {
				console.log('关联考试选择取消')
				this.$refs.exasmSelectBoes.blur();
				this.dengmiDataAdd.examListBoes = false;
				this.dengmiDataAdd.hidenHeight = false;
				this.dengmiDataAdd.examList = [];
				this.dengmiDataAdd.examDataList = [];
			},
			
			/**
			 * @Description: 关联考试显示获取附加分及删除操作
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 16:29:20
			 */
			isOperateShow(dataItem) {
				this.isOperate = dataItem.id;
			},
			/**
			 * @Description: 关联考试隐藏获取附加分及删除操作
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 16:29:28
			 */
			isOperateHide(dataItem) {
				this.isOperate = '';
			},
			/**
			 * @Description: 关联考试信息删除
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 16:29:38
			 */
			deleteExam(dataItem) {
				let index = this.dengmiDataAdd.examDataList.indexOf(dataItem);
				let dataList = this.dengmiDataAdd.examList;
				if (index !== -1) {
					this.dengmiDataAdd.examDataList.splice(index, 1)
				}
				for(let j = 0; j < dataList.length; j++) {
					if(dataItem.id === dataList[j]) {
						this.dengmiDataAdd.examList.splice(j, 1)
					}
				}
				//console.log(this.dengmiDataAdd.examDataList)

				if(this.dengmiDataAdd.examDataList.length <= 0) { 
					//console.log('12121 ',this.dengmiDataAdd.examDataList)
					// 关联考试为空时 隐藏选择弹窗
					this.dengmiDataAdd.hidenHeight = false;
					this.dengmiDataAdd.examListBoes = false;
				}
				if(this.dengmiDataAdd.appendPList.length > 0) {
					for(let j = 0; j < this.dengmiDataAdd.appendPList.length; j++) {
						if(dataItem.id === this.dengmiDataAdd.appendPList[j].id) {
							this.dengmiDataAdd.appendPList.splice(j, 1);
						}
					}

				}
			},
			/**
			 * @Description: 获取关联考试信息附加分
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 16:29:47
			 */
			getExamRadio(val, dataItem) {
				//console.log('获取关联考试信息附加分val ',val)
				console.log('获取关联考试信息附加分dataItem ',dataItem)
				if(val) {
					// dataItem.radio = true;
					this.dengmiDataAdd.appendPList.push({
						id: dataItem.id,
						examName: dataItem.examName,
						examId: dataItem.id,
						isAddScore: dataItem.radio === '' ? '0' : '1',
						gradeId: dataItem.gradeId,
						gradeName: dataItem.gradeName,
						startTime: '',
						endTime: '',
						ruleIds: '',
						detaTime: [],
						tableData: []
					})
				} else {
					for(let k = 0; k < this.dengmiDataAdd.appendPList.length; k++) {
						if(dataItem.id === this.dengmiDataAdd.appendPList[k].id) {
							// dataItem.radio = '';
							this.dengmiDataAdd.appendPList.splice(k, 1)
						}
					}
				}
			},
			/**
			 * @Description: 指标选择弹窗
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 17:50:55
			 */
			targetShowClick(dataItem) {
				this.dialogTargetObj.dataId = dataItem.id;
				this.handleTargetList();
				this.dialogTargetObj.dialogVisible = true;
			},
			/**
			 * @Description: 指标选择
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author: 羡国柱
			 * @Date: 2023-07-23 16:18:05
			 */
			handleCheckChange(data, checked, indeterminate) {
				// 获取选中的数据
				let dataList = [];
				let dataLength = 0;
				dataList = this.$refs.tree.getCheckedNodes(true);
				dataLength = dataList.length;
				this.dialogTargetObj.targetSeleteList = [];
				for(let j = 0; j < dataLength; j++) {
					this.dialogTargetObj.targetSeleteList.push(dataList[j]);
				}
				console.log('指标获取选中的数据=== ', this.dialogTargetObj.targetSeleteList)
			},
			/**
			 * @Description: 查询指标
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author: 羡国柱
			 * @Date: 2023-07-23 16:18:16
			 */
			filterNode(value, data) {
				if (!value) {
					return true;
				}
				return data.ruleName.indexOf(value) !== -1;
			},
			/**
			 * @Description: 指标选择
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author: 羡国柱
			 * @Date: 2023-07-23 16:18:18
			 */
			handleNodeClick(data, node, nodeData) {
				let num = 0;
				// this.dialogTargetObj.targetSeleteList.forEach(item => {
				// 	item == data.label ? num++ : num;
				// })
				// if(num == 0) {
				// 	this.dialogTargetObj.targetSeleteList.push(data.label)
				// }
				// console.log('指标获取选中的数据 ==495', this.dialogTargetObj.targetSeleteList)
			},
			/**
			 * @Description: 指标选择删除
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author: 羡国柱
			 * @Date: 2023-07-23 17:25:07
			 */
			targetDelete(dataItem) {
				let dataList = this.$refs.tree.getCheckedNodes(true);
				let index = this.dialogTargetObj.targetSeleteList.indexOf(dataItem)
				if (index !== -1) {
				  this.dialogTargetObj.targetSeleteList.splice(index, 1)
				}
				// 删除左侧tree选中的节点
				this.$refs.tree.setChecked(dataItem.id, false, true);
			},
			/**
			 * @Description: 指标选择弹窗关闭
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 18:18:35
			 */
			handleTargetClose() {
				let dataList = this.dengmiDataAdd.appendPList;
				let dataId = this.dialogTargetObj.dataId;
				for(let j = 0; j < dataList.length; j++) {
					if(dataId === dataList[j].id) {
						if(dataList[j].tableData.length <= 0) {
							this.dialogTargetObj.targetSeleteList = [];
							this.$refs.tree.setCheckedKeys([]);
						}
					}
				}
				this.dialogTargetObj.dialogVisible = false;
			},
			/**
			 * @Description: 指标选择弹窗取消
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 18:18:35
			 */
			targetSeleCancel() {
				let dataList = this.dengmiDataAdd.appendPList;
				let dataId = this.dialogTargetObj.dataId;
				for(let j = 0; j < dataList.length; j++) {
					if(dataId === dataList[j].id) {
						if(dataList[j].tableData.length <= 0) {
							this.dialogTargetObj.targetSeleteList = [];
							this.$refs.tree.setCheckedKeys([]);

						}
					}
				}
				this.dialogTargetObj.dialogVisible = false;
			},
			/**
			 * @Description: 指标选择弹窗保存
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 18:18:35
			 */
			targetSeleConfirm() {
				let dataList = this.dengmiDataAdd.appendPList;
				let dataId = this.dialogTargetObj.dataId;
				for(let j = 0; j < dataList.length; j++) {
					if(dataId === dataList[j].id) {
						dataList[j].tableData = this.dialogTargetObj.targetSeleteList;
                        this.$refs.dengmiDataAdd.validateField(`appendPList.${j}.tableData`)
					}
				}
				this.dialogTargetObj.dialogVisible = false;
			},
			/**
			 * @Description: 新增方案取消
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 17:11:51
			 */
			examAddCancel() {
				this.$refs.dengmiDataAdd.resetFields();
				this.dengmiDataAdd.examListBox = false;
				this.dengmiDataAdd.examListBoes = false;
				this.dengmiDataAdd.hidenHeight = false;
				this.dengmiDataAdd.title = '';
				this.dengmiDataAdd.grade = [];
				this.dengmiDataAdd.exam = '';
				this.dengmiDataAdd.appendPList = [];
				this.dengmiDataAdd.examSeleteList = [];
				this.dengmiDataAdd.examList = [];
				this.dengmiDataAdd.examDataList = [];
				this.$emit('change', 'NO');
			},
			/**
			 * @Description: 补0操作
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author: 陈金伟
			 * @Date: 2023-08-11 11:18:15
			 * @param {*} num
			 */
			padZero(num) {
				if (num < 10) {
					return '0' + num;
				}
				return num;
			},
			/**
			 * @Description: 时间转换字符串
			 * @DoWhat:
			 * @UseScenarios:
			 * @Attention:
			 * @Author: 陈金伟
			 * @Date: 2023-08-11 11:10:11
			*/
			DateToStringData(data,type){
				// 创建一个日期对象
				let date = new Date(data);
				if(type=='start'){
					// 将时、分、秒部分设置为0
					date.setHours(0);
					date.setMinutes(0);
					date.setSeconds(0);
				}else{
					// 将时、分、秒部分设置为0
					date.setHours(23);
					date.setMinutes(59);
					date.setSeconds(59);
				}
				// 获取日期对象中的年、月、日、时、分、秒部分
				var year = date.getFullYear();
				var month = date.getMonth() + 1; // 月份从0开始，需要加1
				var day = date.getDate();
				var hours = date.getHours();
				var minutes = date.getMinutes();
				var seconds = date.getSeconds();
				var formattedDate = year + '-' + this.padZero(month) + '-' + this.padZero(day) + ' ' + this.padZero(hours) + ':' + this.padZero(minutes) + ':' + this.padZero(seconds);
				return formattedDate
			},
			/**
			 * @Description: 新增方案确定
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 17:11:59
			 */
			examAddConfirm(formName) {
				let obj = {};
				let examList = [];
				let ruleIdsList = [];
				let ruleIds = '';
				let gradeInfoList = [];
				let dataList = this.dengmiDataAdd.appendPList;
				let examDataSeleteList = this.dengmiDataAdd.examList;
				let examDataOptions =  this.dengmiDataAdd.examOptions;
				
				// console.log('dataList15445 ', dataList);
				// console.log('examDataSeleteList', examDataSeleteList);
				// console.log('examDataOptions====', examDataOptions);
				
				dataList.map((item, index)=>{
					
					for(let j = 0; j < item.tableData.length; j++) {
						ruleIdsList.push(item.tableData[j].id)
					}
					if(ruleIdsList.length > 0) {
						let m = new Map();
						ruleIdsList = ruleIdsList.filter(item => !m.has(item) && m.set(item,''))
						ruleIds = ruleIdsList.join(',');
					}
					examList.push({
						examName: item.examName,
						examId: item.examId,
						gradeId: item.gradeId,
						gradeName: item.gradeName,
						isAddScore: item.isAddScore,
						startTime: this.DateToStringData(item.detaTime[0],'start'),
						endTime: this.DateToStringData(item.detaTime[1],'end'),
						ruleIds: ruleIds
					})
				})
				
				examDataOptions.forEach(item => {
					examDataSeleteList.forEach(sitem => {
						if(item.id === sitem) {
							examList.push({
								examName: item.examName,
								examId: item.id,
								gradeId: item.gradeId,
								gradeName: item.gradeName,
								isAddScore: 0,
								startTime: '',
								endTime: '',
								ruleIds: ''
							})
						}
					})
				})
				
				// 重复数据去重
				examList = examList.reduce(function(item, next) {
					   obj[next.examId] ? '' : obj[next.examId] = true && item.push(next);
					   return item;
					}, []);
				
				console.log('examList===== ', examList)
				for(let j = 0; j < examList.length; j++) {
					gradeInfoList.push(examList[j].gradeId)
				}
				
				/**
				 * @description: 年级列表去重
				 * @author: 邵澳
				 */	
				(function(){
					let m = new Map();
					gradeInfoList = gradeInfoList.filter(item => !m.has(item) && m.set(item,''))
				})()		
				this.$refs[formName].validate((valid) => {
					if(valid) {
						this.disabledBtnState = true;
						this._fet('/school/schoolCerProgramme/save', {
							name: this.dengmiDataAdd.title,
							// gradeInfo: this.dengmiDataAdd.grade,
							gradeInfo: gradeInfoList,
							examList: examList
						}).then((res) => {
							if (res.data.code === '200') {
								this.dengmiDataAdd.examListBox = false;
								this.dengmiDataAdd.examListBoes = false;
								this.dengmiDataAdd.hidenHeight = false;
								this.dengmiDataAdd.title = '';
								this.dengmiDataAdd.grade = [];
								this.dengmiDataAdd.exam = '';
								this.dengmiDataAdd.appendPList = [];
								this.dengmiDataAdd.examSeleteList = [];
								this.dengmiDataAdd.examList = [];
								this.dengmiDataAdd.examDataList = [];
								// this.$refs.dengmiDataAdd.resetFields();
								this.$message.success("添加成功！");
								this.$emit('change', 'YES');
								this.disabledBtnState = false;
							} else if (res.data && res.data.msg) {
								this.$message.error(res.data.msg);
								this.disabledBtnState = false;
							}
						});
					}
				});



			}
		}
	}
</script>
<style>
	.programme-add-exam-wrap-option {
		max-width: 444px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	/* 自定义动态插入dom元素 */
	
	/* 关联考试列表弹窗按钮 */
	.exam-wrapers-btn {
		height: 36px;
		line-height: 36px;
		font-size: 14px;
	}
	
	.exam-wrapers-btn .btn-cell {
		float: right;
		cursor: pointer;
	}
	
	.exam-wrapers-btn .confirm-exam-btn-add {
		color: #43c623;
		margin: 0 16px;
	}
	
	.exam-wrapers-btn .confirm-exam-btn-add:hover {
		color: #43a620;
	}
	
	.exam-wrapers-btn .cancel-exam-btn-add {
		color: #f92a2a;
	}
	
	.exam-wrapers-btn .cancel-exam-btn-add:hover {
		color: #fa5f5f;
	}
</style>
<style scoped lang="scss">
	.programme-add-pages {}

	.programme-add-pages .el-date-editor {
		width: 100%;
	}

	.programme-add-pages .exam-wrap {
		position: relative;
	}

	/* 关联考试列表弹窗 */
	.programme-add-pages .exam-wrap {
		position: relative;
	}
	
	.programme-add-pages .exam-wrap .el-select {
		display: block;
	}
	
	.programme-add-pages .exam-wrap .exam-wraper-list {
		position: absolute;
		top: 44px;
		left: 0;
		width: 100%;
		height: 150px;
		border-radius: 4px;
		padding-top: 10px;
		padding-left: 11px;
		padding-right: 9px;
		box-sizing: border-box;
		border: solid 1px #e4e7ed;
		border-radius: 4px;
		background-color: #fff;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
	}
	
	.programme-add-pages .exam-wrap .exam-wraper-list-box {
		width: 100%;
		height: 100%;
		overflow-y: auto;
	}
	
	.programme-add-pages .exam-wrap .exam-wraper-list .exams-title {
		display: flex;
		align-items: center;
		margin-right: 40px;
	}
	
	.programme-add-pages .exam-wrap .exam-wraper-list .exams-title .el-icon-error {
		cursor: pointer;
		font-size: 17px;
		color: #b5b7bb;
	}
	
	.programme-add-pages .exam-wrap .exam-wraper-list .exams-title span{
		width: 158px;
		overflow: hidden; 
		white-space: nowrap; 
		text-overflow: ellipsis; 
	}
	
	.programme-add-pages .exam-wrap .exam-wraper-list .exam-wraper-list-cell {
		display: flex;
		padding-left: 12px;
		border-radius: 4px;
		background-color: #f5f7fa;
		margin-bottom: 4px;
	}
	
	.programme-add-pages .exam-wrap .exam-wraper-arrows {
		position: absolute;
		top: -18px;
		left: 36px;
	}
	
	.programme-add-pages .exam-wrap .exam-wraper-arrows .el-icon-caret-top {
		color: #fff;
	}
	
	/* 选择指标 */
	.target-list {
		padding: 0 20px;
		box-sizing: border-box;
	}

	.target-list-query {
		width: 50%;
	}

	.target-list-number {
		width: 50%;
		padding-left: 20px;
		box-sizing: border-box;
	}

	.target-list-boes {
		display: flex;
		margin-top: 10px;
	}

	.target-list-boes-row {
		width: 50%;
		height: 370px;
		overflow-y: auto;
		border: 1px solid #e2e2e2;
	}

	.target-list-boes-lt /deep/ .el-tree-node__label {
		max-width: 180px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	/* 已选择的指标 */
	.target-list-boes-rt {
		display: flex;
		flex-wrap: wrap;
		align-content:flex-start;
	}

	.target-list-boes-rt .target-list-boes-rt-row {
		height: 30px;
		line-height: 30px;
		padding: 0 10px;
		box-sizing: border-box;
		background-color: #f2f2f2;
		margin-left: 10px;
		margin-top: 10px;
	}

	.target-list-boes-rt .target-selete-cell-name {
		max-width: 180px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		cursor: default;
	}

	.target-selete-cell {
		position: relative;
	}

	.target-selete-cell .target-selete-cell-delete {
		position: absolute;
		top: -12px;
		right: -14px;
	}

	.target-selete-cell .target-selete-cell-delete .el-icon-error {
		color: #ff0000;
		cursor: pointer;
	}
</style>
